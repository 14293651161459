/* TRACKING: INTEGRATIONS */

export enum TRACKING_ID {
  CookiePro = "c1021239-0e7d-437b-84c8-7e31c445e652",
  SegmentDev = "qsaDIDtHqCFMG5XJX706sYVDVRTWwEdp",
  SegmentProd = "ItwFBwQFLVFBWftTS6NO8pHY9tDHw93w",
  GoogleAnalytics = "G-4V17FFVS2W", // GA4
  GoogleOptimize = "OPT-N6TJQB2",
  GoogleTagManager = "GTM-KF8CQDR",
  GoogleAds = "",
  Hubspot = "4633075",
  Linkedin = "1068066",
  Facebook = "2357677947784430", // "1471480133151637"
  Partnerstack = "pk_sqnCeTwsFtaayb8uL6wHk8NjV5G0LAT7",
  Intercom = "hvj2kcvi",
  Hotjar = "3120893"
};

export const TRACKING_LIB = {

  GoogleOptimize: `
    let gOptimize = document.createElement("script");
    gOptimize.async = true;
    gOptimize.src = "https://www.googleoptimize.com/optimize.js?id=${TRACKING_ID.GoogleOptimize}";
    document.head.appendChild(gOptimize);
    `,

  CookiePro: `
    let CookieProId = location.host === "perpetua.io" ? "${TRACKING_ID.CookiePro}" : "${TRACKING_ID.CookiePro}-test";
    let autoBlocker = document.createElement("script");
    autoBlocker.src = "https://cookie-cdn.cookiepro.com/consent/" + CookieProId + "/OtAutoBlock.js";
    document.head.appendChild(autoBlocker);
    let mainLib = document.createElement("script");
    mainLib.src = "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js";
    mainLib.setAttribute("data-domain-script", CookieProId);
    mainLib.setAttribute("crossorigin", "anonymous");
    mainLib.setAttribute("data-document-language", "true");
    document.head.appendChild(mainLib);
    // function OptanonWrapper(){};
    `,

  Segment: `
    let SegmentId = location.host === "perpetua.io" ? "${TRACKING_ID.SegmentProd}" : "${TRACKING_ID.SegmentDev}";
    ! function() {
      var analytics = window.analytics = window.analytics || [];
      if (!analytics.initialize)
        if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
        else {
          analytics.invoked = !0;
          analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
          analytics.factory = function(e) {
            return function() {
              var t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics
            }
          };
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e];
            analytics[key] = analytics.factory(key)
          }
          analytics.load = function(key, e) {
            var t = document.createElement("script");
            t.type = "text/javascript";
            t.async = !0;
            t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
            // t.onload = function() {console.log("Segment lib executed")};
            var n = document.getElementsByTagName("script")[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e
          };
          analytics._writeKey = SegmentId;
          analytics.SNIPPET_VERSION = "4.15.3";
          analytics.load(SegmentId);
          // analytics.page();
        }
      }();`,

  GoogleAnalytics: `
    let ga4 = document.createElement("script");
    ga4.async = true;
    ga4.src = "https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID.GoogleAnalytics}";
    let c = document.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(ga4, c);
    window.dataLayer = window.dataLayer || [];
    function gtag() {window.dataLayer.push(arguments);}
    gtag("js", new Date());
    gtag("config", "${TRACKING_ID.GoogleAnalytics}", {send_page_view: true});
    // gtag("config","AW-GOOGLE_ADS_CONVERSION_ID");
    // console.log("GA4 is loaded");
    `,

  Linkedin: `
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push("${TRACKING_ID.Linkedin}");
    (function (l) {
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);`,

  Facebook: `
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "${TRACKING_ID.Facebook}");
    fbq("track", "PageView");`,

  Partnerstack: `
    (function() {
      var gs = document.createElement('script');
      gs.src = 'https://js.partnerstack.com/v1/';
      gs.type = 'text/javascript';
      gs.async = 'true';
      gs.onload = gs.onreadystatechange = function() {
        var rs = this.readyState;
        if (rs && rs != 'complete' && rs != 'loaded') return;
        try {
            growsumo._initialize('${TRACKING_ID.Partnerstack}');
            if (typeof(growsumoInit) === 'function') {
                growsumoInit();
            }
        } catch (e) {}
      };
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gs, s);
    })();`,

  Intercom: `
    var IntercomLoadUseCases = ["mousemove", "scroll", "touchstart"]; // click
    var IntercomLoader = function() {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "${TRACKING_ID.Intercom}"
      };

      var w = window;
      var ic = w.Intercom;

      if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() {i.c(arguments)};
        i.q = [];
        i.c = function(args) {i.q.push(args)};
        w.Intercom = i;

        var IntercomScript = document.createElement("script");
        IntercomScript.type = "text/javascript";
        IntercomScript.async = true;
        IntercomScript.src = "https://widget.intercom.io/widget/${TRACKING_ID.Intercom}";
        var IntercomInsert = d.getElementsByTagName("script")[0];
        IntercomInsert.parentNode.insertBefore(IntercomScript, IntercomInsert);
      };
      IntercomLoadUseCases.forEach((e) => {window.removeEventListener(e, IntercomLoader)});
    };
    IntercomLoadUseCases.forEach((e) => {window.addEventListener(e, IntercomLoader, {once: true})});`,
  
  Hotjar: `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${TRACKING_ID.Hotjar},hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.setAttribute('crossorigin', 'anonymous');
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,

  Youtube: `
    var scriptUrl = 'https:\/\/www.youtube.com\/s\/player\/d759e46a\/www-widgetapi.vflset\/www-widgetapi.js';
    try {
      var ttPolicy = window.trustedTypes.createPolicy("youtube-widget-api", {
        createScriptURL: function(x) {
          return x
        }
      });
      scriptUrl = ttPolicy.createScriptURL(scriptUrl)
    } catch (e) {}
    var YT;
    if (!window["YT"]) YT = {
      loading: 0,
      loaded: 0
    };
    var YTConfig;
    if (!window["YTConfig"]) YTConfig = {
      "host": "https://www.youtube.com"
    };
    if (!YT.loading) {
      YT.loading = 1;
      (function() {
        var l = [];
        YT.ready = function(f) {
          if (YT.loaded) f();
          else l.push(f)
        };
        window.onYTReady = function() {
          YT.loaded = 1;
          for (var i = 0; i < l.length; i++) try {
            l[i]()
          } catch (e$0) {}
        };
        YT.setConfig = function(c) {
          for (var k in c)
            if (c.hasOwnProperty(k)) YTConfig[k] = c[k]
        };
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.id = "www-widgetapi-script";
        a.src = scriptUrl;
        a.async = true;
        var c = document.currentScript;
        if (c) {
          var n = c.nonce || c.getAttribute("nonce");
          if (n) a.setAttribute("nonce", n)
        }
        var b = document.getElementsByTagName("script")[0];
        b.parentNode.insertBefore(a, b)
      })()
    };`,

  Hubspot: `
    (function() {
      let hsSnippet = document.createElement("script");
      hsSnippet.id="hs-script-loader"
      hsSnippet.async = true;
      hsSnippet.defer = true;
      hsSnippet.src = "//js.hs-scripts.com/${TRACKING_ID.Hubspot}.js";
      let hsSnippetSrc = document.getElementsByTagName("script")[0];
      hsSnippetSrc.parentNode.insertBefore(hsSnippet, hsSnippetSrc);
    })();`,

};

// List of known web crawlers
export const TRACKING_BOT = [
  "datadogsynthetics",
  "googlebot",
  "headlesschrome",
  "bingbot",
  "slurp",
  "duckduckbot",
  "baiduspider",
  "yandexbot",
  "exabot",
  "facebot",
  "ia_achiver"
];

/** TRACKING: EVENTS */

// Name of user action
export enum Name {
  Cta = "CTA Clicked",
  FormCompleted = "Form Completed",
  Page = "Page Viewed",
  BlogPost = "Blog Post Viewed",
  BlogCategory = "Blog Category Viewed",
  VideoCategory = "Video Category Viewed",
  Conversation = "Conversation Started",
  NewsletterSignup = "Newsletter Signup",
  DemoSignup = "Demo Signup",
  ContentDownloads = "Content Downloads",
  VideoPlayback = "Video Playback"
}

// The category of the page
export enum Category {
  Blog = "Blog",
  Home = "Home",
  Product = "Product",
  FormInput = "Form Input",
  ButtonClick = "Button Click",
  PageView = "Page View",
  ProfileSignup = "Profile Sign Up",
  Downloadable = "Downloadable",
  Prism = "Prism",
  Benchmarker = "Benchmarker",
  ManagedService = "Managed Service",
  YouTubeEvents = "YouTube Events"
}

// Component type
export enum Type {
  Button = "button",
  Banner = "banner"
}

// Module or component location
export enum Location {
  Navigation = "navigation",
  BottomCta = "bottom cta",
  Herobanner = "herobanner",
  Feature = "feature card",
  ManagedService = "managed service",
  Pricing = "pricing card",
  FeatureContainer = "feature split container",
  Announcement = "announcement banner",
  BlogList = "blog list"
}

export enum FormName {
  BlogNewsletter = "Newseletter Signup",
  HeroEmailInput = "Hero Email Input"
}

export enum ContentTitle {
  MarketingSite = "marketing site"
}

export enum FormType {
  SignUp = "Sign Up",
  WEBINAR = "Webinar",
  RESOURCE = "Resource",
  AFFILIATE = "Affiliate",
  DATA_CONSULT = "Data Consult",

}

export enum UTM {
  campaign = "utm_campaign",
  medium = "utm_medium",
  source = "utm_source",
  term = "utm_term",
  content = "utm_content"
}

export enum platformID {
  gclid = "gclid",
  fbclid = "fbclid",
  li_fat_id = "li_fat_id",
  campaignid = "campaignid",
  adgroupid = "adgroupid",
  keyword = "keyword"
}

export const CTA_ID = {
  signinNav: {id: "nav-signin", event: "Sign In - Navigation"},
  getStartedNav: {id: "nav-getstarted", event: "Get Started - Navigation"},
  getStartedHeader: {id: "header-getstarted", event: "Get Started - Header"},
  getStartedBottom: {id: "bottom-getstarted", event: "Get Started - Bottom"},
  demoHeader: {id: "header-bookademo", event: "Demo Request - Header"},
  demoMiddle: {id: "middle-bookademo", event: "Demo Request - Middle"},
  demoBottom: {id: "bottom-bookademo", event: "Demo Request - Bottom"},
  resourcesGridAssets: {id: "get-resource-page", event: "Content Download - Grid Pool"},
  resourcesPageHeader: {id: "header-download-resource", event: "Content Download - Header"},
  resourcesPageBottom: {id: "bottom-download-resource", event: "Content Download - Bottom"},
};

export const PaidSocialEvents = {
  GetStartedFlow: {
    Linkedin: {
      ButtonClick: "9173060",
      EmailSubmit: "9173068",
      PersonalInfoSubmit: {
        AllTiers: "9173076",
        P2PlusTiers: "9253444"
      },
      ApiIntegration: {
        AllTiers: "9253460",
        P2PlusTiers: "9253492"
      }
    },
    Facebook: {
      ButtonClick: "Get Started - Button Click",
      EmailSubmit: "Get Started - Email Submit",
      PersonalInfoSubmit: {
        AllTiers: "Get Started - Personal Info Submit_All leads",
        P2PlusTiers: "Get Started - Personal Info Submit_P2+"
      },
      ApiIntegration: {
        AllTiers: "Get Started - API Integration_All leads",
        P2PlusTiers: "Get Started - API Integration_P2+"
      }
    }
  },
  DemoRequest: {
    Linkedin: {
      AllTiers: "9173084",
      P2PlusTiers: "9253452"
    },
    Facebook: {
      AllTiers: "Demo Request_All leads",
      P2PlusTiers: "Demo Request_P2+"
    }
  },
  ContentDownloads: {
    Linkedin: "9173092",
    Facebook: "Content Download"
  },
  NewsletterSignup: {
    Facebook: "Newsletter Signup"
  }
};

export const YouTubePlaybackStatus = {
  0: "Video ended",
  1: "Video playing",
  2: "Video paused",
  3: "Video buffering",
  5: "Video cued"
};
